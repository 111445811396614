.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#pdfview {
  margin-top: 20px;
  width: 80%;
  height: 80%;
  border-radius: 8px;
}

#videos {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* height: 100%; */
}

#subscriber {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 10 !important;
}

#publisher {
  position: absolute;
  width: 360px;
  height: 240px;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}

#screenshot {
  z-index: 10000;
  position: absolute;
  top: 20px;
  right: 10px;
  height: 200px;
  width: 200px;
  display: none;
}

.react-datepicker-popper {
  width: max-content;
}

.newCaseInput {
  width: 100%;
}

.react-datepicker-wrapper {
  width: inherit;
}

.relative-select {
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
dyte-camera-toggle {
  display: none !important;
}