/* Styles */
$theme-colors: (
  'primary': #8852cc,
);
$enable-shadows: false;
$input-btn-focus-width: 0;
$btn-border-radius: 3rem;
$input-padding-x: 0;
$thumbnail-border-width: 0;
$progress-bg: rgba(255, 255, 255, 0.3);
$progress-height: 0.2rem;
$progress-bar-bg: #fff;
$fa-font-path: 'https://s3.ap-south-1.amazonaws.com/visit-hra';
$font-family-base: 'Brandon', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$card-border-width: 3px;

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

@font-face {
  font-family: 'BrandonText-Bold';
  src: url('/fonts/BrandonText-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'BrandonText-Black';
  src: url('/fonts/BrandonText-Black.otf') format('opentype');
}
@font-face {
  font-family: 'BrandonText-Medium';
  src: url('/fonts/BrandonText-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'BrandonText-Regular';
  src: url('/fonts/BrandonText-Regular.otf') format('opentype');
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

.form-control {
  border: 0;
  border-bottom: $input-border-width solid $input-border-color;
}

/* Create a custom radio button */
input[type='radio'] ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eee;
}

input[type='checkbox'] ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
input[type='radio']:hover ~ .checkmark {
  border: 1px solid theme-color('primary');
}

/* When the radio button is checked, add a blue background */
input[type='radio']:checked ~ .checkmark {
  border: 1px solid theme-color('primary');
  background-color: $white;
}

/* On mouse-over, add a grey background color */
input[type='checkbox']:hover ~ .checkmark {
  border: 1px solid theme-color('primary');
}

/* When the radio button is checked, add a blue background */
input[type='checkbox']:checked ~ .checkmark {
  background-color: theme-color('primary');
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  position: absolute;
  display: none;
  content: '';
}

input[type='checkbox'] ~ .checkmark:after {
  top: 5px;
  left: 9px;
  width: 5px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: solid white;
  border-width: 0 3px 3px 0;
}

/* Show the indicator (dot/circle) when checked */
input[type='radio']:checked ~ .checkmark:after {
  display: block;
  background-color: theme-color('primary');
}

/* Show the indicator (dot/circle) when checked */
input[type='checkbox']:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
input[type='radio'] ~ .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  display: flex;
  overflow-y: hidden;
}

#modal-root,
#error-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  // display: flex;
  align-items: stretch;
  justify-content: center;
}

#video-root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  // display: flex;
  align-items: stretch;
  justify-content: center;
}

.modal-container {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: stretch;
}

input.visit-input {
  font-family: Inter-Medium, Lato, sans-serif;
  font-size: 1.8rem;
  border: none;
}

input {
  font-family: Inter-Medium, Lato, sans-serif;
  font-size: 1.4rem !important;
}

textarea {
  font-family: Inter-Medium, Lato, sans-serif;
  font-size: 1.4rem !important;
}

.select-options {
  font-size: 14px;
}

.slide-next-enter,
.slide-next-appear {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(100%);
}

.slide-next-enter.slide-next-enter-active,
.slide-next-appear.slide-next-appear-active {
  transition: 300ms linear;
  transform: unset;
}

.slide-next-exit {
  transform: unset;
}

.slide-next-exit.slide-next-exit-active {
  transition: 300ms linear;
  transform: translateX(100%);
}

.slide-prev-enter,
.slide-prev-appear {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.slide-prev-enter.slide-prev-enter-active,
.slide-prev-appear.slide-prev-appear-active {
  transition: 300ms linear;
  transform: unset;
}

.slide-prev-exit {
  transform: unset;
}

.slide-prev-exit.slide-prev-exit-active {
  transition: 300ms linear;
  transform: translateX(-100%);
}

.overflow-visible {
  overflow: visible !important;
}

.custom-control-label {
  transform: scale(1.5);
}

.custom-switch {
  padding-left: 3.4rem;
}

.react-select__value-container {
  font-family: Inter, Lato, sans-serif;
  font-size: 14px;
}

.react-select__option {
  font-family: Inter, Lato, sans-serif;
  font-size: 12px;
}
